import { Block, Navbar, Page } from 'framework7-react';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { TeamsList } from '../components/teams/TeamList';
import { beConnectorInstance } from '../js/BeConnector';
import { CenteredPreloader } from '../js/utils/visHelper';


const PAGE_STATES = {
    "UNINITIALIZED": "UNINITIALIZED",
    "LOADING": "LOADING",
    "READY": "READY",
}



export default function (_props) {
    const stateRef = useRef(PAGE_STATES.UNINITIALIZED);
    const [showLoader, setShowLoader] = useState(true);
    const [list, setList] = useState();
    const [refreshTimer, setRefreshTimer] = useState(0);
    const _settingToSaveRef = useRef();
    const stats = useRef({ cancellations: 0, completions: 0 });
    const tableHeaderTranslations = useRef({ MNAVN: "Navn", MEDARBNR: "ID nummer", "NEW_APP": "Ny App", "status": "Status", "startid": "Starttid", KNAVN: "Kunde", address: "Adresse", elapsed: "Tid brugt" });
    const TABLE_ELEMENTS = ["MNAVN", "MEDARBNR", "NEW_APP", "status", "startid", "elapsed", "KNAVN", "address"]
    //const tableHeaderTranslations = { MNAVN: "Navn", MEDARBNR: "ID nummer", "status": "Status", "startid": "Starttid", KNAVN: "Kunde", address: "Adresse", elapsed: "Tid brugt" }

    setTimeout(() => {
        stateRef.current = PAGE_STATES.UNINITIALIZED;
        setRefreshTimer(refreshTimer + 1)
    }, 1000 * 60);
    console.log("refreshTimer", refreshTimer);
    useEffect(() => {
        async function fetchData() {
            if (stateRef.current == PAGE_STATES.UNINITIALIZED) {
                stateRef.current = PAGE_STATES.LOADING;
                refreshTimer == 0 && setShowLoader(true);
                const rawData = (await beConnectorInstance.loadTeamStates());
                if (rawData.status == "FAIL") {
                    console.log("Failed to load data");
                    return;
                }
                stats.current.cancellations = 0;
                stats.current.completions = 0;
                const states = [];
                const lookup = {};
                rawData.data.forEach((item) => {
                    if (!lookup[item.MEDARBNR]) {

                        let appUsage = "❌";
                        switch (true) {
                            case item.newAppUseCount > 5:
                                appUsage = "😁";
                                break;
                            case item.newAppUseCount > 4:
                                appUsage = "😊";
                                break;
                            case item.newAppUseCount > 3:
                                appUsage = "🤨";
                                break;
                            case item.newAppUseCount > 2:
                                appUsage = "😒";
                                break;
                            case item.newAppUseCount > 1:
                                appUsage = "🤬";
                                break;
                            case item.newAppUseCount == 0:
                                appUsage = "❌";
                                break;
                        }

                        lookup[item.MEDARBNR] = {
                            MEDARBNR: item.MEDARBNR,
                            MNAVN: item.MNAVN,
                            status: "Ingen kunder idag",
                            startid: "",
                            KNAVN: "",
                            rawItem: item,
                            color: "#1e1e1e",
                            notDone: 0,
                            isAtCustomer: false,
                            jobDone: 0,
                            NEW_APP: appUsage
                        };
                        states.push(lookup[item.MEDARBNR]);
                    }

                    if (item.STATUS == 9) {
                        stats.current.cancellations++;
                    }

                    if (item.KLARMELDT + "" == "1") {
                        lookup[item.MEDARBNR].jobDone++;
                        stats.current.completions++;
                        if (lookup[item.MEDARBNR].notDone == 0 && lookup[item.MEDARBNR].isAtCustomer != true) {
                            {
                                lookup[item.MEDARBNR].status = "Afsluttet dagen (" + lookup[item.MEDARBNR].jobDone + " kunde(r))";
                                lookup[item.MEDARBNR].color = "#3c493d";
                            }
                        }
                    }
                    if (item.KLARMELDT + "" != "1" && item.STATUS != 9 && item.SCANSTATUS + "" != "1") {
                        lookup[item.MEDARBNR].notDone++;
                        lookup[item.MEDARBNR].status = "Kunder afventer: " + (lookup[item.MEDARBNR].notDone);
                        lookup[item.MEDARBNR].color = lookup[item.MEDARBNR].isAtCustomer ? "#2e3f2e" : "#333333";
                        lookup[item.MEDARBNR].isAtCustomer && (lookup[item.MEDARBNR].status += " - Hos kunde");
                    }
                    //item.SCANSTATUS = (Math.random() * 10 > 7) ? 1 : -1;


                    if (item.SCANSTATUS + "" == "1") {
                        lookup[item.MEDARBNR].isAtCustomer = true;
                        lookup[item.MEDARBNR].status = lookup[item.MEDARBNR].notDone == 0 ? "Hos sidste kunde" : "Kunder afventer: " + (lookup[item.MEDARBNR].notDone) + " - hos kunde";

                        let startDate = new Date(item.KALSTART)
                        // extract just hour minute and seconds to string (using luxon)
                        let timeStr = DateTime.fromJSDate(startDate).toFormat("hh:mm:ss");
                        // elapsed = time elapsed from start (using luxon)
                        let elapsed = DateTime.fromJSDate(startDate).diffNow().negate().toFormat("hh:mm:ss");
                        lookup[item.MEDARBNR].startid = timeStr;
                        lookup[item.MEDARBNR].address = item.ADDR1 + " " + item.POSTNR + " " + item.CITY;
                        lookup[item.MEDARBNR].KNAVN = item.KNAVN;
                        lookup[item.MEDARBNR].color = "#2e3f2e";
                        // lookup[item.MEDARBNR].elapsed = "00:" + (Math.floor(Math.random() * 40) + 10) + ":00";
                        lookup[item.MEDARBNR].elapsed = elapsed;


                    }
                });

                states.sort((a, b) => {
                    const adone = (a.notDone == 0 && a.isAtCustomer != true);
                    const bdone = (b.notDone == 0 && b.isAtCustomer != true);
                    const anoCust = (a.jobDone == 0 && a.isAtCustomer != true && a.notDone == 0);
                    const bnoCust = (b.jobDone == 0 && b.isAtCustomer != true && b.notDone == 0);

                    if (anoCust && !bnoCust) return 1;
                    if (!anoCust && bnoCust) return -1;

                    if (adone && !bdone) return 1;
                    if (!adone && bdone) return -1;
                    //return 0
                    let compresult = a.MNAVN.localeCompare(b.MNAVN)
                    return compresult;
                    //return a.MNAVN.localeCompare(b.MNAVN)
                });
                tableHeaderTranslations.current["status"] = "Status (" + stats.current.completions + " udført, " + stats.current.cancellations + " aflyst)";
                setList(states);
                setShowLoader(false);
                stateRef.current = PAGE_STATES.READY;
            }

        }
        fetchData();
    }, [refreshTimer]);



    //const showMain = (stateRef.current == PAGE_STATES.READY);
    const showMain = !!(list && list.length > 0);
    /* useMemo(() => {
        if (showMain) {
            console.log("list", list)
        }
    }, [showMain]) */
    return (
        <Page>
            <Navbar title={`Team Live View:`} backLink="Back" />
            <Block strong style={{ zIndex: "unset" }}>
                {showLoader && <CenteredPreloader />}
            </Block>
            {showMain && <TeamsList
                onItemClick={(item) => {
                    console.log("item", item)
                    //let a = 55.684887 - Math.random() * 0.1;
                    //let b = 12.580205
                    // openDirectionsToAddress("" + a + ", " + "12.580205")

                }}
                translation={tableHeaderTranslations.current}
                mainFields={TABLE_ELEMENTS}
                list={list}
            />}
        </Page>
    )
}