import { feConfig } from "./controllers/FEConfig";
import { cachedDataHelper } from "./utils/MemHelper";

let DEBUG_PHONE_COUNT = 1;



export default class OfferHandler {
    static MASTER_URL = "https://dinside.servicehuset.dk/sh7/";


    static statusLookup = { BINDING_OFFER_SENT: '2', SENT: '0', SEEN: '1' };

    static hasChanged = false;
    static loading = false;
    static list = {};
    static ready = false;
    static instance = new OfferHandler();
    static CONTACT_GROUP_ITEMS = {
        'email': true,
        'navn': true,
        'gade': true,
        'gadenr': true,
        'postnummer': true,
        'by': true,
        'telefon': true
    }

    constructor() {
        feConfig.getOrderData().then((orderData) => {
            //console.log("Order data:", orderData);
            lookupArray = orderData;
        });
    }


    static getHasChanged(setFalse = true) {
        if (OfferHandler.hasChanged) {
            OfferHandler.hasChanged = !setFalse;
            return true;
        }
        return false;
    }

    retrieveTimeline(uid) {
        let cacheName = "retrieveTimeline" + uid;
        let result = cachedDataHelper.get(cacheName);

        return new Promise((resolve, reject) => {

            if (result) {
                console.log("retrieveTimeline: Cache hit :) ");
                return result;
            }

            this.tryCallRemote("getTimeline", { uid: uid }).then(value => {
                cachedDataHelper.store(cacheName, value, 60 * 100);
                resolve(value);
            }).catch(reason => {
                reject(reason);
            })


        })
    }

    static getListwithOfferActive = false

    getListwithOffer(uid) {
        return new Promise((resolve, reject) => {
            if (OfferHandler.loading) {
                return;
            }


            //console.log("retrieving list with uid", uid);
            this.getData("BINDING_OFFER_SENT").then(_status => {
                if (OfferHandler.list.uidList[uid]) {
                    resolve(OfferHandler.list);
                    return;
                }
                this.getData("SENT").then(_status => {
                    if (OfferHandler.list.uidList[uid]) {
                        resolve(OfferHandler.list);
                        return;
                    }
                    reject("NOT FOUND");
                })

            }).catch(_reason => {
                console.error("retrieving offers failed:", _reason);
            });

        });


    }

    getData(status) {
        let endpoint = "getOffers" + (status ? "?status=" + status : "?status=SENT");
        OfferHandler.loading = true;
        return new Promise((resolve) => {
            fetch(OfferHandler.MASTER_URL + endpoint)
                .then(res => res.json())
                .then(
                    (result) => {
                        //console.log("getData Received:", result);
                        //OfferHelper.list = result;
                        OfferHandler.ready = true;
                        OfferHandler.loading = false;
                        this.compileList(result, status == "BINDING_OFFER_SENT");

                        resolve("Ready");
                    },

                    (error) => {
                        console.log("Received error:", error);
                        OfferHandler.loading = false;
                    }
                )
        });
    }

    static callQueue = [];
    static isCalling = false;

    tryCallRemote(endpoint, args, data, retries = 3) {
        return new Promise((resolve, reject) => {
            this.callRemote(endpoint, args, data).then((result) => {
                resolve(result);
            }, (_error) => {
                console.log("Prepare for retry...");
                if (retries > 0)
                    setTimeout(() => {
                        this.tryCallRemote(endpoint, args, data, retries - 1).then((result) => {
                            resolve(result)
                        },
                            (error) => {
                                reject(error);
                            })

                    }, 4000);

            })
        })

    }



    callRemote(endpoint, args, data) {
        OfferHandler.isCalling = true;
        return new Promise((resolve, reject) => {
            let request = undefined;
            if (data) {
                request = {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'text/plain'
                        // 'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }
            }

            if (args) {
                let divider = '?';
                Object.keys(args).forEach(key => {
                    endpoint += divider;
                    endpoint += key + '=' + args[key];
                    divider = '&';
                });
            }

            fetch(OfferHandler.MASTER_URL + endpoint, request)
                .then(res => res.json())
                .then(
                    (result) => {
                        //console.log("Received :", result);
                        resolve(result);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log("Received error:", error);
                        reject(error);
                    }
                ).finally(() => {
                    OfferHandler.isCalling = false;

                })
        });
    }


    updateOffer(uid) {
        return new Promise((resolve) => {
            let data = OfferHandler.list.uidList[uid].offer;
            this.tryCallRemote("updateOffer", { uid: uid }, data).then((result) => {
                resolve(result);


            });
        })
    }


    getPostcode(offer) {
        if ('postcode' in offer) return offer.postcode;
        let items = offer.formItems;
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            if (item.name === 'Postnummer' || item.name === 'Postal code') {
                return item.value;
            }

        }
        return '';
    }

    compileList(data, showOld = false) {

        let orderedList = { uidList: {} };

        data.forEach(element => {
            let offer = JSON.parse(element.json);
            element.offer = offer;
            element.priority = "prioritet" in offer ? offer.prioritet : 2;
            element.creationDate = new Date(element.created_time);
            element.age = Math.floor((Date.now() - element.creationDate.getTime()) / (1000 * 60 * 60));
            element.area = offer.area;
            element.name = offer.userName;
            element.email = offer.userEmail;
            element.moneyValue = offer?.offeredPrice?.total[1] || "";

            if (parseInt(element.moneyValue) < 400) {
                element.moneyValue = "";
                offer.offeredPrice = undefined;
            }

            element.postcode = this.getPostcode(offer);
            element.state = OfferHandler.statusLookup[element.status];
            element.offer.offerStatus = element.status;
            !element.postcode && (element.postcodeMissing = true);
            !element.postcode && (element.postcode = '9999');
            !element.area && (element.area = 'area99');
            if (!("P" + element.priority in orderedList))
                orderedList["P" + element.priority] = [];
            if (element.offer.formVersion && element.offer.formVersion >= 100) {
                if (element.age < 600 || showOld || element.postcode == '9999') {
                    orderedList["P" + element.priority].push(element);
                    orderedList.uidList[element.uid] = element;

                    let items = this.covertToObject(offer.formItems);
                    element.dictionary = items;

                    // debugging:
                    if (element.postcode == '9999' && element.postcodeMissing != true) {
                        for (let index = 0; index < 5; index++) {
                            let newElem = { ...element };
                            newElem.offer = JSON.parse(element.json);
                            newElem.uid = 'SH-LD7LDYNL-FAKEDEBUG0' + DEBUG_PHONE_COUNT
                            newElem.name += "" + DEBUG_PHONE_COUNT;
                            newElem.offer.uid = newElem.uid;
                            newElem.dictionary = this.covertToObjectDEBUG(newElem.offer.formItems);
                            orderedList["P" + element.priority].push(newElem);
                            orderedList.uidList[newElem.uid] = newElem;
                        }
                    }



                }
            }




        });

        OfferHandler.list = orderedList;
    }
    static DEBUG_POST_CODES = [2000, 2100, 4000, 7190, 9320, 5800, 2000, 2000, 2000, 2000, 2000, 1572, 1654, 7190];

    covertToObjectDEBUG(list) {
        let result = {};
        list.forEach((item) => {
            item.id == 'navn' && (item.value = "" + item.value + DEBUG_PHONE_COUNT);
            item.id == 'telefon' && (item.value = "" + (parseInt(item.value) + (DEBUG_PHONE_COUNT++)));

            item.id == 'postnummer' && (item.value = OfferHandler.DEBUG_POST_CODES[DEBUG_PHONE_COUNT]);
            result[item.id] = item;
        });
        return result;
    }

    covertToObject(list) {
        let result = {};
        list.forEach((item) => {
            result[item.id] = item;
        });
        return result;
    }

    getParsedOffer(offerListItem) {
        if (offerListItem.parsedOffer) return offerListItem.parsedOffer;
        //let parsed = {};
        let formItems = offerListItem.offer.formItems;
        let contact = [];
        let other = [];
        let steetItem = [undefined, undefined];


        formItems.forEach(item => {
            if (item.id == 'frekvens') {
                if (item.value in { 'Bare en enkelt gang': true, 'Flytterengøring': true, 'Movine out cleaning': true, 'Only once': true }) {
                    offerListItem.moneyValue = "";
                    offerListItem.offer.offeredPrice = undefined;

                }
            }
            //console.log("item", item);
            item.id == 'gade' && (steetItem[0] = item);
            if (item.id == 'gadenr') {
                steetItem[1] = item;
                return;
            }



            if (item.id && OfferHandler.CONTACT_GROUP_ITEMS[item.id]) {
                contact.push(item);

            }
            else {
                other.push(item);
            }
        })

        if (steetItem[0] && steetItem[1]) steetItem[0].value += " " + steetItem[1].value;


        other = reorderByLookup(other, lookupArray);

        offerListItem.offer.parsedFormItems = { contact: contact, other: other };
        offerListItem.parsedOffer = offerListItem.offer;

        return offerListItem.parsedOffer;
    }


}
let lookupArray = [
    { id: 'frekvens' },
    { id: 'dage' },
    { id: 'boligtype' },
    { id: 'kvm' },
    { id: 'basement' },
    { id: 'firstTime' },
    { id: 'parkering2' },
    { id: 'alarm' },
    { id: 'bad' },
    { id: 'gulve' },
    { id: 'husdyr' },
    { id: 'husdyr2' },
    { id: 'opstart' },
    { id: 'konsulent' },
];
function reorderByLookup(originalArray, orderLookupArray) {
    const reorderedArray = [];
    orderLookupArray.forEach(({ id }) => {
        const matchingObj = originalArray.find((obj) => {
            const regex = new RegExp(`^${id}`);
            return regex.test(obj.id);
        });
        if (matchingObj) {
            reorderedArray.push(matchingObj);
        }
    });
    originalArray.forEach((obj) => {
        if (!reorderedArray.includes(obj)) {
            reorderedArray.push(obj);
        }
    });
    return reorderedArray;
}
